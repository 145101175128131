import { lazy } from 'ice';

export default [
  {
    /* 企业管理列表 */
    path: '/customerManage/business',
    component: lazy(() => import(/* webpackChunkName: "customer" */ '@/pages/CustomerManage/Business')),
  },
  {
    /* 企业管理-企业详情 */
    path: '/customerManage/business-detail',
    component: lazy(() => import(/* webpackChunkName: "customer" */ '@/pages/CustomerManage/Business/Detail'))
  },
  {
    /* 帐号管理列表 */
    path: '/customerManage/account',
    component: lazy(() => import(/* webpackChunkName: "customer" */ '@/pages/CustomerManage/Account'))
  },
  {
    /* 账号管理-账号详情 */
    path: '/customerManage/account-detail',
    component: lazy(() => import(/* webpackChunkName: "customer" */ '@/pages/CustomerManage/Account/Detail'))
  },
  {
    /* 我邀请的客户列表 */
    path: '/customerManage/invitatory-customer',
    component: lazy(() => import(/* webpackChunkName: "customer" */ '@/pages/CustomerManage/InvitatoryCustomer'))
  },
  {
    /* 待审核列表 */
    path: '/customerManage/business-waitingAuth',
    component: lazy(() => import(/* webpackChunkName: "customer" */ '@/pages/CustomerManage/BusinessWaitingAuth'))
  },
  {
    /* 审核记录列表 */
    path: '/customerManage/business-auth',
    component: lazy(() => import(/* webpackChunkName: "customer" */ '@/pages/CustomerManage/BusinessAuthRecords'))
  },
  {
    /* 审核详情 */
    path: '/customerManage/business-approval',
    component: lazy(() => import(/* webpackChunkName: "customer" */ '@/pages/CustomerManage/BusinessWaitingAuth/Approval'))
  },
];

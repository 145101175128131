const global = {
  pageSize: 10, // 分页列表每页数据个数
  searchCount: 3 // 限制搜索条件最初展示个数
};
// 接口返回成功的code
export const SUCCESS_CODE = '00000'; // 成功
export const ERROR_CODE = '99999'; // 系统异常
export const TOKEN_ILLEGAL_CODE = '04001'; // token非法

// font js
export const fontScriptUrl = '//at.alicdn.com/t/font_2971920_pt684xpf02o.js'

// 后管首页路由path配置
export const homeRouterPath = '/customerManage/business-waitingAuth'

export const tableCellWidth = {
  mini: 80,
  small: 100,
  medium: 140,
  time: 160,
  large: 180,
  xlarge: 220,
  xxlarge: 260
};

export default global;

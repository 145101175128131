export default {
  dev: {
    // baseURL: 'https://gateway.rongkong.net.cn/', // 本地开发
    // baseURL: 'https://qingdao-gateway.sdpjw.cn/', // 本地开发
    // ossPath: 'shendu-sit/', // oss存放地址
    // inviteLink: 'draft.rongkong.net.cn' // 邀请链接，此处配置前台的域名
    baseURL: "https://gateway.ronghetong.net/", // 生产后台服务域名
    // baseURL: "http://10.10.20.23:8080/", // 生产后台服务域名
    ossPath: "basics/", // oss存放地址
    inviteLink: "www.ronghetong.net", // 邀请链接，此处配置前台的域名
    webUrl: "https://admin.ronghetong.net", // 当前项目访问域名
  },
  dev: {
    baseURL: "https://gateway-dev.ronghetong.net/", // 测试环境
    ossPath: "basics/", // oss存放地址
    inviteLink: "www.ronghetong.net", // 邀请链接，此处配置前台的域名
  },
  dev1: {
    baseURL: "https://gateway.rongkong.net.cn/", // 测试环境1
    ossPath: "shendu-sit/", // oss存放地址
    inviteLink: "draft.rongkong.net.cn", // 邀请链接，此处配置前台的域名
  },
  dev2: {
    baseURL: "https://gateway.rongkong.net.cn/", // 测试环境2
    ossPath: "shendu-sit/", // oss存放地址
    inviteLink: "draft.rongkong.net.cn", // 邀请链接，此处配置前台的域名
  },
  test2: {
    baseURL: "https://qingdao-gateway.sdpjw.cn/", // 仿生环境-新架构
    ossPath: "", // oss存放地址
    inviteLink: "http://qingdao-web.spbbank.cn", // 邀请链接，此处配置前台的域名
  },
  prod: {
    baseURL: "https://gateway.ronghetong.net/", // 生产后台服务域名
    ossPath: "basics/", // oss存放地址
    inviteLink: "www.ronghetong.net", // 邀请链接，此处配置前台的域名
    webUrl: "https://admin.ronghetong.net", // 当前项目访问域名
  },
};

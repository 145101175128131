import LoginBlock from './components/LoginBlock';
import backMain from '@/assets/imgs/back-main.png';

import styles from './components/LoginBlock/index.module.scss';

const Login = () => {
  return (
    <section className={styles.containerSection}>
      <div className={styles.leftContainer}>
        <img className={styles.backMainImg} src={backMain} alt="" />
      </div>
      <div className={styles.rightContainer}>
        <LoginBlock />
      </div>
    </section>
  );
};

export default Login;

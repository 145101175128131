import { lazy } from 'ice'

/* 额度管理 */
export default [
  {
    /* 商票额度列表 */
    path: '/quotaManage/business-quota',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "quota" */ '@/pages/QuotaManage/BusinessQuota'))
  },
  {
    /* 银票额度列表 */
    path: '/quotaManage/bank-quota',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "quota" */ '@/pages/QuotaManage/BankQuota'))
  },
]


import { config, history } from 'ice';
import { Message } from '@alifd/next';
import { v4 as uuidv4 } from 'uuid';
import { SUCCESS_CODE, ERROR_CODE, TOKEN_ILLEGAL_CODE } from '@/utils/config';

const toLogin = () => {
  history.push('/user/login');
};
/**
  * 请求失败后的错误统一处理
  * @param {Number} status 请求失败的状态码
  */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
  // 401: 未登录状态，跳转登录页
    case 401:
      toLogin();
      break;
    // 403 token过期
    // 清除token并跳转登录页
    case 403:
      Message.warning('登录过期，请重新登录');
      sessionStorage.removeItem('token');
      // store.commit('loginSuccess', null);
      setTimeout(() => {
        toLogin();
      }, 1000);
      break;
    // 404请求不存在
    case 404:
      Message.warning('请求的资源不存在');
      break;
    default:
      console.log(other);
      break;
  }
};
const request = {
  baseURL: config.baseURL,
  interceptors: {
    request: {
      onConfig: (reqConfig) => {
        const newConfig = reqConfig;
        newConfig.headers['Content-Type'] = 'application/json;charset=UTF-8';
        const token = localStorage.getItem('sd-token');
        newConfig.headers.token = token == null ? '' : token
        newConfig.headers.serialId = uuidv4()
        // newConfig.headers.timestamp = new Date().getTime()
        newConfig.headers.terminalType = 0
        // newConfig.headers.tenantId = 1

        if (reqConfig.formData) {
          newConfig.headers['Content-Type'] = reqConfig.contentType;
        }
        return newConfig;
      },
      onError: () => { }
    },
    response: {
      onConfig: (resConfig) => {
        if (resConfig && resConfig.status === 200) {
          const code = resConfig.data ? resConfig.data.code : '';
          if (code === ERROR_CODE) { // 系统内部错误
            Message.warning('信息有误，请稍后再试！');
          } else if (code === TOKEN_ILLEGAL_CODE) { // token非法
            const token = localStorage.getItem('sd-token');
            if (token) {
              Message.warning('登录超时，请重新登录');
              localStorage.clear();
            }
            toLogin();
          } else { // 其他提示信息
            // eslint-disable-next-line no-lonely-if
            if (code !== SUCCESS_CODE) {
              Message.warning(resConfig.data.message);
            }
          }
          return Promise.resolve(resConfig);
        } else if (resConfig.status === 204) {
          return Promise.resolve(resConfig)
        } else {
          Message.warning('信息有误，请稍后再试！');
          return Promise.reject(resConfig);
        }
      },
      onError: (error) => {
        if (error && error.response) {
          const { response } = error;
          Message.warning('请求失败，请稍后再试！');
          // 请求已发出，但是不在2xx的范围
          errorHandle(response.status, response.data.message);
          return Promise.reject(response);
        } else {
          // 处理断网的情况
          // eg:请求超时或断网时，更新state的network状态
          // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
          Message.warning('网络不好，请稍后再试！');
          return Promise.reject();
        }
      }
    },
  }
};

export default request;

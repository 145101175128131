import { Message } from '@alifd/next'
import {
  userLogin,
  captchaLogin,
  queryDictForList,
  userLogout,
} from '@/services/login'
import { history } from 'ice'
import { SUCCESS_CODE } from '@/utils/config'

export const SET_USERINFO = 'counter/SET_USERINFO'
export const SET_DICT = 'counter/SET_DICT'

const initialState = {
  userInfo: {},
  authResource: [],
  confDict: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USERINFO:
      return {
        ...state,
        userInfo: {
          avatar:
            'https://img.alicdn.com/tfs/TB1.ZBecq67gK0jSZFHXXa9jVXa-904-826.png',
          ...action.userInfo,
        },
        authResource: action.authResource,
      }
    case SET_DICT:
      return {
        ...state,
        confDict: action.confDict,
      }
    default:
      return state
  }
}

/**
 * @desc: 地址字段转换
 * @param {*} target:Array
 * @param {*} sourceList:Array
 * @return Array
 */
// const formatAreaTree = (target, sourceList) => {
//   if (!sourceList || sourceList.length <= 0) {
//     return [];
//   }
//   sourceList.map((each) => { // eslint-disable-line array-callback-return
//     const { confAreaId, name, children } = each;
//     const item = {
//       label: name,
//       value: confAreaId,
//     }
//     if (!!children && children.length > 0) {
//       item.children = formatAreaTree([], children);
//     }
//     target.push(item);
//   });

//   return target;
// };

export const loginAsync = (params) => {
  return async (dispatch) => {
    const result = await userLogin(params)
    const { code, data } = result
    if (code !== SUCCESS_CODE) {
      return result
    }
    const { token, resourceList, ...rest } = data
    localStorage.setItem('sd-token', token)
    delete data.token
    // 单独存放用户信息到本地，保持与原有字段信息相同
    localStorage.setItem('currentUserInfo', JSON.stringify(data))
    dispatch({
      type: SET_USERINFO,
      userInfo: rest,
      authResource: resourceList,
    })
    return result
  }
}

export const getDictForList = (params) => {
  return async (dispatch) => {
    const { code: dictCode, data: dictData } = await queryDictForList(params)
    if (dictCode === SUCCESS_CODE) {
      dispatch({
        type: SET_DICT,
        confDict: dictData,
      })
    }
  }
}

export const logoutAsync = () => {
  return async () => {
    const { code } = await userLogout()
    if (code === SUCCESS_CODE) {
      Message.success('已登出')
      localStorage.clear()
      history.push('/user/login')
    }
  }
}

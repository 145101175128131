import { lazy } from 'ice'

export default [
  {
    /* 商票签约 */
    path: '/signingManage/business-ticket',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "report" */ '@/pages/SigningManage/BusinessTicket'))
  },
  {
    /* 银票签约 */
    path: '/signingManage/bank-ticket',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "report" */ '@/pages/SigningManage/BankTicket'))
  }
]

import { lazy } from 'ice'
import UserLayout from '@/layouts/UserLayout';
import Login from '@/pages/Login';
import Register from '@/pages/Register';
import BasicLayout from '@/layouts/BasicLayout';
import routerArr from '@/router/index';

import FeedbackNotFound from '@/pages/FeedbackNotFound';
import FeedbackServerError from '@/pages/FeedbackServerError';

const routerConfig = [
  {
    path: '/user',
    component: UserLayout,
    children: [
      {
        path: '/login',
        component: Login,
      },
      {
        path: '/register',
        component: Register,
      },
      {
        path: '/',
        exact: true,
        redirect: '/user/login',
      }
    ],
  },
  {
    path: '/',
    component: BasicLayout,
    children: [
      {
        path: '/',
        exact: true,
        redirect: '/user/login',
      },
      {
        path: '/account/refreshPwd', // 修改密码
        component: lazy(() => import('@/pages/Account/RefreshPwd'))
      },
      ...routerArr,
      {
        path: '/feedback/404',
        component: FeedbackNotFound,
      },
      {
        path: '/feedback/500',
        component: FeedbackServerError,
      },
    ],
  },
];
export default routerConfig;

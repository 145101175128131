import { request } from 'ice';

/**
 * 用户管理列表
 * @param {*} data object
 */
export const queryUserForPage = (data = {}) => request({
  url: '/basics/sysUser/queryForPage',
  method: 'post',
  data
});

// 查询用户列表(客户管理->分配客户需要查询用户)
export const reqUserList = (data) => request({
  url: '/basics/sysUser/queryUserList',
  method: 'post',
  data: data || {}
})

/**
 * 用户关系列表查询
 * @param {*} data object
 */
// export const querysysUserForMap = (data) => request({
//   url: '/basics/sysUser/queryForMap',
//   method: 'post',
//   data: data || {}
// })
/**
 * 用户列表查询查询
 * @param {*} data object
 */
// export const querysysUserForList = (data) => request({
//   url: '/sysUser/queryForList',
//   method: 'post',
//   data: data || {}
// })

/**
 * 用户修改
 * @param {*} data object
 */
export const updateUserInfo = (data = {}) => request({
  url: '/basics/sysUser/update',
  method: 'post',
  data
});

/**
 * 新增用户
 * @param {*} data object
 */
export const resetPwd = (data = {}) => request({
  url: '/basics/sysUser/resetPassword',
  method: 'post',
  data
});

/**
 * 删除
 * @param {*} data object
 */
export const deleteOneUser = (data = {}) => request({
  url: '/basics/sysUser/delete',
  method: 'post',
  data
});

/**
 * 获取分配角色中所有角色
 * @param {*} data object
 */
export const getRoleInfo = (data = {}) => request({
  url: '/basics/sysRole/queryUserRoleList',
  method: 'post',
  data
});

/**
 * 获取分配角色已选中角色
 * @param {*} data object
 */
export const getUserRole = (data = {}) => request({
  url: '/basics/sysUser/queryUserRoleList',
  method: 'post',
  data
});

/**
 * 查询用户可见组
 * @param {*} data object
 */
export const getSysGroupData = (data = {}) => request({
  url: '/basics/sysGroup/querysysGroupList',
  method: 'post',
  data
});

/**
 * 用户角色分配
 * @param {*} data object
 */
export const changeRole = (data = {}) => request({
  url: '/basics/sysUser/updateRole',
  method: 'post',
  data
});

/**
 * 查询机构可关联的组列表
 * @param {*} data object
 */
export const getUserGroupList = (data = {}) => request({
  url: '/basics/sysGroup/queryBySysUserId',
  method: 'post',
  data
});

/**
 * 查询机构可关联的组列表
 * @param {*} data object
 */
export const getUserGroups = (data = {}) => request({
  url: '/basics/sysGroup/queryGroupForList',
  method: 'post',
  data
});

/**
 * 用户分配组
 * @param {*} data object
 */
export const changeUserGroup = (data = {}) => request({
  url: '/basics/sysGroup/updateUserGroup',
  method: 'post',
  data
});

/**
 * 用户禁用
 * @param {*} data object
 */
export const disable = (data = {}) => request({
  url: '/basics/sysUser/disable',
  method: 'post',
  data
});

/**
 * 用户启用
 * @param {*} data object
 */
export const enable = (data = {}) => request({
  url: '/basics/sysUser/enable',
  method: 'post',
  data
});

/**
 * 用户启用
 * @param {*} data object
 */
export const getUserDetail = (data = {}) => request({
  url: '/basics/sysUser/queryDetail',
  method: 'post',
  data
});

/**
 * 系统产品查询
 * @param {*} data object
 */
export const requestSystemProduct = (data = {}) => request({
  url: '/basics/product/querySystemProduct',
  method: 'post',
  data
});

/**
 * 查询用户产品权限
 * @param {*} data object
 */
export const requestProductAuth = (data = {}) => request({
  url: '/basics/sysUser/queryProductAuth',
  method: 'post',
  data
});

/**
 * 配置用户产品权限
 * @param {*} data object
 */
export const requestUpdateProductAuth = (data = {}) => request({
  url: '/basics/sysUser/updateProductAuth',
  method: 'post',
  data
});

/**
 * 角色管理-查询审批组信息
 * @param {*} data object
 */
export const requestGroupList = (data = {}) => request({
  url: '/basics/sysRole/queryGroupList',
  method: 'post',
  data
});

/**
 * 用户-查询已配置的审批组
 * @param {*} data object
 */
export const requestGroups = (data = {}) => request({
  url: '/basics/sysUser/queryGroup',
  method: 'post',
  data
});

/**
 * 用户-配置审批组
 * @param {*} data object
 */
export const requestUpdateGroups = (data = {}) => request({
  url: '/basics/sysUser/updateGroup',
  method: 'post',
  data
});

/**
 * 分配下属-查询下属用户树
 * @param {*} data object
 */
export const requestSubordinateTree = (data = {}) => request({
  url: '/basics/sysUser/querySubordinateTree',
  method: 'post',
  data
});

/**
 * 分配下属
 * @param {*} data object
 */
export const requestAssignSubordinate = (data = {}) => request({
  url: '/basics/sysUser/assignSubordinate',
  method: 'post',
  data
});

/**
 * 分配下属-查询我的下属
 * @param {*} data object
 */
export const requestSubordinate = (data = {}) => request({
  url: '/basics/sysUser/querySubordinate',
  method: 'post',
  data
});

/**
 * 用户菜单权限列表查询
 * @param {*} data object
 */
export const requestSysUserAuthForList = (data = {}) => request({
  url: '/basics/sysUser/querySysUserAuthForList',
  method: 'post',
  data
});

/**
 * 用户权限配置
 * @param {*} data object
 */
export const requestUpdateSysUserAuth = (data = {}) => request({
  url: '/basics/sysUser/updateSysUserAuth',
  method: 'post',
  data
});

/**
 * 用户权限配置-复制
 * @param {*} data object
 */
export const requestCopySysUserAuth = (data = {}) => request({
  url: '/basics/sysUser/copySysUserAuth',
  method: 'post',
  data
});

// 新增用户 sysUser/create
export const requestCreateUser = (data = {}) => request({
  url: '/basics/sysUser/create',
  method: 'post',
  data
});

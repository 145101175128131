import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist';
//  存储机制，可换成其他机制，当前使用sessionStorage机制
import storage from 'redux-persist/lib/storage'
import rootReducer from '@/models/index'

const initialState = {}
const middleware = [thunk]

const storageConfig = {
  key: 'root', // 必须有的
  storage, // 缓存机制
  whitelist: ['login', 'user'] // login,user 数据会存入缓存，其他不会存，适用于大多数数据并不会实时从后台拿数据
}

const myPersistReducer = persistReducer(storageConfig, rootReducer)
const composedEnhancers = compose(
  applyMiddleware(...middleware),
)

const store = createStore(
  myPersistReducer,
  initialState,
  composedEnhancers
)
export const persistor = persistStore(store)

export default store

import { lazy } from 'ice'

export default [
  {
    /* banner管理列表 */
    path: '/operationManage/banner',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "operation" */ '@/pages/OperationManage/Banner'))
  },
  {
    /* 文章管理列表 */
    path: '/operationManage/article',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "operation" */ '@/pages/OperationManage/Article'))
  },
  {
    /* 新增/编辑文章 */
    path: '/operationManage/article-create',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "operation" */ '@/pages/OperationManage/Article/Create'))
  },
  {
    path: '/operationManage/acceptor-whitelist',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "operation" */ '@/pages/OperationManage/AcceptorWhiteList'))
  }
]


import "core-js/stable";
import "regenerator-runtime/runtime";
import * as React from 'react';
import { runApp } from 'ice';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

import store, { persistor } from '@/store';
import LocaleProvider from '@/components/LocaleProvider';
import { getLocale } from '@/utils/locale';
import requestConfig from '@/services/request';

const locale = getLocale();
const appConfig = {
  app: {
    rootId: 'ice-container',
    addProvider: ({ children }) => {
      return (
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <LocaleProvider locale={locale}>{children}</LocaleProvider>
          </PersistGate>
        </Provider>
      );
    },
  },
  router: {
    type: 'browser',
    basename: '/',
    fallback: <div>loading...</div>,
    modifyRoutes: (routes) => {
      // 动态修改路由
      return routes;
    },
  },
  request: requestConfig,
};
runApp(appConfig);

  
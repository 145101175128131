import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'ice';
import { Nav, Icon } from '@alifd/next';
import { getNoAuthMenuData } from '@/utils/authCheck';
import { asideMenuConfig } from '../../menuConfig';
import { fontScriptUrl } from '@/utils/config';

import styles from './index.module.scss';

const { SubNav } = Nav;
const NavItem = Nav.Item;

const CustomIcon = Icon.createFromIconfontCN({
  scriptUrl: fontScriptUrl,
});

function getNavMenuItems(menusData) {
  if (!menusData) {
    return [];
  }

  return menusData.filter((item) => item.name && !item.hideInMenu).map((item, index) => getSubMenuOrItem(item, index));
}

function getSubMenuOrItem(item) {
  const icon = item.iconFont ? (
    <span className={styles.menu_icon}>
      <CustomIcon type={item.iconFont} size="small" />
    </span>
  ) : (
    item.icon
  );
  if (item.children && item.children.some((child) => child.name)) {
    const childrenItems = getNavMenuItems(item.children);

    if (childrenItems && childrenItems.length > 0) {
      const subNav = (
        <SubNav key={item.path} icon={icon} label={item.name}>
          {childrenItems}
        </SubNav>
      );
      return subNav;
    }

    return null;
  }

  const navItem = (
    <NavItem key={item.path} icon={icon} style={{ paddingRight: '20px' }}>
      <Link style={{ marginLeft: item.icon ? 0 : '10px' }} to={item.path}>
        {item.name}
      </Link>
    </NavItem>
  );
  return navItem;
}

const Navigation = (props, context) => {
  const { location } = props;
  const { pathname } = location;
  const openKeyDefault = pathname.substring(0, pathname.lastIndexOf('/'));
  const [openKeys, setOpenKeys] = useState([openKeyDefault]);
  const { isCollapse } = context;
  const newAsideMenu = getNoAuthMenuData(asideMenuConfig);

  return (
    <Nav
      className="nav-wrapper"
      type="normal"
      openKeys={openKeys}
      onOpen={(keys) => {
        setOpenKeys(keys);
      }}
      selectedKeys={[pathname]}
      defaultSelectedKeys={[pathname]}
      embeddable
      activeDirection="right"
      openMode="single"
      iconOnly={isCollapse}
      hasArrow={false}
      hasTooltip
      style={{ width: '200px' }}
      mode={isCollapse ? 'popup' : 'inline'}
      triggerType={isCollapse ? 'hover' : 'click'}
    >
      {getNavMenuItems(newAsideMenu)}
    </Nav>
  );
};

Navigation.contextTypes = {
  isCollapse: PropTypes.bool,
};
const PageNav = withRouter(Navigation);
export default PageNav;

import React from 'react';
import { Overlay, Menu, Icon } from '@alifd/next';
import { history } from 'ice';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fontScriptUrl } from '@/utils/config'
import { logoutAsync } from '@/models/login';
import { setInviteCode } from '@/models/systemManage/user'

import styles from './index.module.scss';

const { Item } = Menu;
const { Popup } = Overlay;

const CustomIcon = Icon.createFromIconfontCN({
  scriptUrl: fontScriptUrl,
});

const UserProfile = ({ name, userRoleList, roleList }) => {
  let roleLabel = [];
  roleList.forEach((item) => {
    if (userRoleList.indexOf(item.value) !== -1) {
      roleLabel.push(item.label);
    }
  });

  return (
    <div className={styles.profile}>
      <div className={styles.avatar}>
        <CustomIcon type="icon-denglutouxiang" />
      </div>
      <div className={styles.content}>
        <h4>{name}</h4>
        <span>{roleLabel && roleLabel.length > 0 ? roleLabel.join(' | ') : ''}</span>
      </div>
    </div>
  );
};

const HeaderAvatar = (props) => {
  const { userInfo, roleList, userRoleList } = props;
  const { loginAccount } = userInfo;

  const updatePassword = () => {
    history.push('/account/refreshPwd');
  };

  const setIsInvite = () => {
    props.setInviteCode(true)
  }

  return (
    <Popup
      trigger={
        <div className={styles.headerAvatar}>
          <CustomIcon type="icon-denglutouxiang" size="large" />
          <span
            style={{
              marginLeft: 10,
            }}
          >
            {loginAccount}
          </span>
        </div>
      }
      triggerType="click"
    >
      <div className={styles.avatarPopup}>
        <UserProfile {...userInfo} userRoleList={userRoleList} roleList={roleList} />
        <Menu className={styles.menu}>
          <Item onClick={setIsInvite}>
            <CustomIcon type="icon-yaoqingma" size="small" />
            业务员邀请码
          </Item>
          <Item onClick={updatePassword}>
            <CustomIcon type="icon-mima" size="small" />
            修改密码
          </Item>
          <Item onClick={props.logoutAsync}>
            <CustomIcon type="icon-admin" size="small" />
            退出
          </Item>
        </Menu>
      </div>
    </Popup>
  );
};

// HeaderAvatar.defaultProps = {
//   name: 'MyName',
//   mail: 'name@gmail.com',
//   avatar: 'https://img.alicdn.com/tfs/TB1.ZBecq67gK0jSZFHXXa9jVXa-904-826.png',
// };
// export default HeaderAvatar;

const mapStateToProps = ({ login, user }) => ({ ...login, roleList: user.roleList, userRoleList: user.userRoleList });
const mapDispatchToProps = (dispatch) => bindActionCreators({ logoutAsync, setInviteCode }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderAvatar);

import { queryBusinessCapitalList, queryBankCapitalList, queryBusinessProductList } from '@/services/capitalManage'
import { SUCCESS_CODE } from '@/utils/config';

export const SET_PRODUCTLIST = 'counter/SET_PRODUCTLIST'
export const SET_PARTNERLIST = 'counter/SET_PARTNERLIST'
export const SET_BANKPARTNERLIST = 'counter/SET_BANKPARTNERLIST'

const initialState = {
  // 资方
  partnerList: null,
  // 产品
  productList: null,
  // 银票资方
  bankPartnerList: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PARTNERLIST:
      return {
        ...state,
        partnerList: action.partnerList
      }
    case SET_BANKPARTNERLIST:
      return {
        ...state,
        bankPartnerList: action.bankPartnerList
      }
    case SET_PRODUCTLIST:
      return {
        ...state,
        productList: action.productList
      }
    default:
      return state
  }
}

// 获取资方列表数据
export const getPartnerList = (params) => {
  return async dispatch => {
    const result = await queryBusinessCapitalList(params);
    const { code, data } = result
    if (code !== SUCCESS_CODE) {
      return result
    }
    dispatch({
      type: SET_PARTNERLIST,
      partnerList: data || [],
    })
    return result
  }
}

// 获取银票资方列表数据
export const getBankPartnerList = (params) => {
  return async dispatch => {
    const result = await queryBankCapitalList(params);
    const { code, data } = result
    if (code !== SUCCESS_CODE) {
      return result
    }
    dispatch({
      type: SET_BANKPARTNERLIST,
      bankPartnerList: data || [],
    })
    return result
  }
}

// 获取产品列表
export const getProductList = (params) => {
  return async dispatch => {
    const result = await queryBusinessProductList(params);
    const { code, data } = result
    if (code !== SUCCESS_CODE) {
      return result
    }
    dispatch({
      type: SET_PRODUCTLIST,
      productList: data || [],
    })
    return result
  }
}

import { lazy } from 'ice';

export default [
  {
    // 用户管理列表
    path: '/systemManage/user',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "system" */'@/pages/SystemManage/User/List'))
  },
  {
    // 用户详情
    path: '/systemManage/user-detail',
    component: lazy(() => import(/* webpackChunkName: "system" */'@/pages/SystemManage/User/Detail')),
  },
  {
    // 用户管理修改
    path: '/systemManage/user-update',
    component: lazy(() => import(/* webpackChunkName: "system" */'@/pages/SystemManage/User/Update'))
  },
  {
    // 用户管理-权限管理
    path: '/systemManage/user-auth',
    component: lazy(() => import(/* webpackChunkName: "system" */'@/pages/SystemManage/User/Auth'))
  },
  {
    // 用户管理-权限管理-数据权限配置
    path: '/systemManage/user-dataAuth',
    component: lazy(() => import(/* webpackChunkName: "system" */'@/pages/SystemManage/User/DataAuthConfig'))
  },
  {
    path: '/systemManage/role', // 系统管理 -> 角色管理
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "system" */'@/pages/SystemManage/Role/List')),
  },
  {
    path: '/systemManage/role-create', // 系统管理 -> 角色管理 -> 角色新增
    component: lazy(() => import(/* webpackChunkName: "system" */'@/pages/SystemManage/Role/CreateRole')),
  },
  {
    path: '/systemManage/role-update', // 系统管理 -> 角色管理 -> 角色新增
    component: lazy(() => import(/* webpackChunkName: "system" */'@/pages/SystemManage/Role/CreateRole')),
  },
  {
    /* 数据字典列表 */
    path: '/systemManage/dict',
    component: lazy(() => import(/* webpackChunkName: "system" */'@/pages/SystemManage/Dict/List')),
  },
  {
    path: '/systemManage/loginLog',
    component: lazy(() => import(/* webpackChunkName: "system" */'@/pages/SystemManage/Log/List')),
  },
  // {
  //   path: '/systemManage/homeConfig',
  //   component: lazy(() => import(/* webpackChunkName: "system" */'@/pages/SystemManage/Home')),
  // },
  // {
  //   path: '/systemManage/timingTask',
  //   component: lazy(() => import('@/pages/SystemManage/Task'))
  // },
];

import React from 'react';
import styles from './index.module.scss';
import { redirectHasAuthPage } from '@/utils/authCheck'

export default function UserLayout({ children }) {
  const token = localStorage.getItem('sd-token');
  // 已经登录了，直接进入主页面
  if (token) {
    redirectHasAuthPage()
  }
  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

import { history } from 'ice'
import store from '@/store';
import { asideMenuConfig } from '@/layouts/BasicLayout/menuConfig'

export function getAuthPath() {
  let paths = ['/', '/user/login', '/user/register', '/feedback/404', '/account/refreshPwd']
  const { login } = store.getState();
  if (login.authResource && login.authResource.length) {
    let authPaths = login.authResource.map(item => {
      return item.url
    })
    paths = [...paths, ...authPaths]
  }
  return paths
}

/**
 * 判断路由是否有权限，并返回带权限标志的路由
 * @param {Array}  routerConfig
 */
export function getNoAuthRouterData(routerConfig) {
  const newRouter = []
  routerConfig.forEach(item => {
    const { children, ...res } = item
    const newItem = res
    newItem.children = []
    children.forEach(el => {
      let authority = true
      if (getAuthPath().includes(el.path)) {
        authority = false
      }
      newItem.children.push({ ...el, authority })
    })
    newRouter.push(newItem)
  })
  return newRouter
}

export function getNoAuthMenuData(menuConfig) {
  const newMenu = []
  menuConfig.forEach(item => {
    if (getAuthPath().includes(item.path)) {
      const { children, ...res } = item
      const newItem = res
      newItem.children = []
      children.forEach(el => {
        if (getAuthPath().includes(el.path)) {
          newItem.children.push(el)
        }
      })
      newMenu.push(newItem)
    }
  })
  return newMenu
}

/**
 * 重定向至当前用户有权限的第一个页面
 */
export function redirectHasAuthPage() {
  let authSideRoutes = getNoAuthMenuData(asideMenuConfig)
  authSideRoutes = authSideRoutes.filter(
    (item) => item.children.length > 0
  )
  if (authSideRoutes.length === 0) {
    // props.logoutAsync().then(() => {
    //   Message.error('此用户无访问权限')
    //   setIsLoading(false)
    // })
    return
  }
  history.replace(authSideRoutes[0].children[0].path)
}
const headerMenuConfig = [];
const asideMenuConfig = [
  {
    name: '客户管理',
    path: '/customerManage',
    iconFont: 'icon-kehuguanli',
    children: [
      {
        name: '待审核',
        path: '/customerManage/business-waitingAuth'
      },
      {
        name: '企业管理',
        path: '/customerManage/business',
      },
      {
        name: '账号管理',
        path: '/customerManage/account',
      },
      {
        name: '我邀请的',
        path: '/customerManage/invitatory-customer',
      },
      {
        name: '审核记录',
        path: '/customerManage/business-auth'
      }
    ],
  },
  {
    name: '签约管理',
    path: '/signingManage',
    iconFont: 'icon-qianyueguanli',
    children: [
      // {
      //   name: '银票签约',
      //   path: '/signingManage/bank-ticket',
      // },
      {
        name: '商票签约',
        path: '/signingManage/business-ticket',
      },
    ],
  },
  {
    name: '额度查询',
    path: '/quotaManage',
    iconFont: 'icon-educhaxun',
    children: [
      // {
      //   name: '银票额度',
      //   path: '/quotaManage/bank-quota'
      // },
      {
        name: '商票额度',
        path: '/quotaManage/business-quota',
      },
    ],
  },
  {
    name: '订单管理',
    path: '/orderManage',
    iconFont: 'icon-dingdanguanli',
    children: [
      // {
      //   name: '银票询价',
      //   path: '/orderManage/bankEnquiry'
      // },
      // {
      //   name: '银票贴现',
      //   path: '/orderManage/bankDiscount'
      // },
      {
        name: '商票订单',
        path: '/orderManage/business-order'
      },
      {
        name: '贸易订单',
        path: '/orderManage/trade-order'
      }
    ]
  },
  {
    name: '运营管理',
    path: '/operationManage',
    iconFont: 'icon-yunyingguanli',
    children: [
      {
        name: 'Banner管理',
        path: '/operationManage/banner'
      },
      {
        name: '文章管理',
        path: '/operationManage/article'
      },
      {
        name: '承兑人白名单',
        path: '/operationManage/acceptor-whitelist'
      }
    ]
  },
  {
    name: '资方管理',
    path: '/capitalManage',
    iconFont: 'icon-zifangguanli',
    children: [
      // {
      //   name: '银票资方',
      //   path: '/capitalManage/bank-capital'
      // },
      {
        name: '商票资方',
        path: '/capitalManage/business-capital',
      },
    ],
  },
  {
    name: '报表管理',
    path: '/reportManage',
    iconFont: 'icon-baobiaoguanli',
    children: [
      {
        name: '平台总业绩',
        path: '/reportManage/platform-achievement'
      },
      {
        name: '资金方统计',
        path: '/reportManage/capital-statistic'
      }
    ]
  },
  {
    name: '系统管理',
    path: '/systemManage',
    iconFont: 'icon-xitongguanli',
    children: [
      {
        name: '用户管理',
        path: '/systemManage/user',
      },
      {
        name: '角色管理',
        path: '/systemManage/role'
      },
      {
        name: '数据字典',
        path: '/systemManage/dict',
      },
      {
        name: '登录日志',
        path: '/systemManage/loginLog',
      },
      // {
      //   name: '首页配置',
      //   path: '/systemManage/homeConfig'
      // },
      // {
      //   name: '定时任务',
      //   path: '/systemManage/timingTask'
      // },
    ],
  },
];
export { headerMenuConfig, asideMenuConfig };

import { useEffect, useState } from 'react'
import { Button, Input, Form, Field, Message } from '@alifd/next'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import styles from './index.module.scss'
import userIcon from '@/assets/imgs/user-icon.png'
import passwordIcon from '@/assets/imgs/password-icon.png'
import codeIcon from '@/assets/imgs/code-icon.png'
import { v4 as uuidv4 } from 'uuid'
import { queryImageCaptcha, getLoginCaptcha } from '@/services/login'
import { loginAsync, getDictForList, logoutAsync } from '@/models/login'
import { queryAllRole, queryUserRole } from '@/models/systemManage/user'
import { area } from '@/assets/js/area'

import { SUCCESS_CODE } from '@/utils/config'
import { redirectHasAuthPage } from '@/utils/authCheck'

import store from '@/store'

const { Item } = Form

const LoginBlock = (props) => {
  const field = Field.useField()
  const { init, setError } = field
  const [imageUrl, setImageUrl] = useState('')
  const [sendBtnText, setSendBtnText] = useState('发送验证码') // 发送验证码按钮文案
  const [sending, toggleSending] = useState(false) // 发送验证码标记
  const [countdown, setCountdown] = useState(null) // 按钮倒计时
  const [deviceNumber, setDeviceNumber] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // 获取验证码
  const getImageCaptcha = () => {
    const uuid = uuidv4()
    field.getValue('code') && field.setValue('code', '')
    queryImageCaptcha({ deviceNumber: uuid }).then((res) => {
      const { code, data } = res
      if (code === SUCCESS_CODE) {
        setImageUrl(`data:image/jpeg;base64,${res.data.imageCaptcha}`)
        setDeviceNumber(data.deviceNumber)
      }
    })
  }

  useEffect(() => {
    getImageCaptcha()
  }, [])

  const sendPhoneCaptcha = () => {
    const mobile = field.getValue('loginAccount')
    if (!mobile) {
      setError('loginAccount', '请输入账号')
      return
    }
    if (sending) return
    toggleSending(true)
    getLoginCaptcha({ mobile })
      .then(({ code }) => {
        if (code !== SUCCESS_CODE) return Promise.reject()
        let second = 60
        setCountdown(
          setInterval(() => {
            if (second === 0) {
              clearInterval(countdown)
              setSendBtnText('发送验证码')
              setCountdown(null)
              return
            }
            setSendBtnText(`${second--}s`)
          }, 1000)
        )
        setSendBtnText(`${second--}s`)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        toggleSending(false)
      })
  }

  const formatAreaTree = (target, sourceList) => {
    if (!sourceList || sourceList.length <= 0) {
      return []
    }
    sourceList.map((each) => {
      // eslint-disable-line array-callback-return
      const { confAreaId, name, children } = each
      const item = {
        label: name,
        value: confAreaId,
      }
      if (!!children && children.length > 0) {
        item.children = formatAreaTree([], children)
      }
      target.push(item)
    })

    return target
  }

  const getArea = () => {
    const target = formatAreaTree([], area)
    localStorage.setItem('confArea', JSON.stringify(target))
  }

  // 登录事件
  const handleSubmit = (e) => {
    e.preventDefault()
    field.validatePromise().then(({ errors, values }) => {
      if (errors) return
      const params = {
        ...values,
        deviceNumber,
      }
      setIsLoading(true)
      props.loginAsync(params).then((res) => {
        const { code, data } = res
        if (code !== SUCCESS_CODE) {
          setIsLoading(false)
          getImageCaptcha()
          return
        }

        const { sysUserId } = data
        // 获取用户角色
        props.queryAllRole({ sysUserId }).then(({ code: roleCode }) => {
          if (roleCode !== SUCCESS_CODE) {
            return
          }
          props.queryUserRole({ sysUserId })
        })

        // 获取字典
        props.getDictForList({ row: 0 })
        // 缓存省市区数据
        getArea()

        // 页面重定向
        redirectHasAuthPage()
      })
    })
  }

  // 账号密码登录模版
  const accountForm = (
    <>
      <Item>
        <Input
          name="loginAccount"
          {...init('loginAccount', {
            rules: [{ required: true, message: '用户名必填' }],
          })}
          maxLength={20}
          innerBefore={<img className={styles.imgIcon} src={userIcon} alt="" />}
          placeholder="请输入账号"
        />
      </Item>
      <Item>
        <Input.Password
          innerBefore={
            <img className={styles.imgIcon} src={passwordIcon} alt="" />
          }
          name="password"
          {...init('password', {
            rules: [{ required: true, message: '密码必填' }],
          })}
          htmlType="password"
          placeholder="请输入密码"
        />
      </Item>
      <div className={styles.mobileCaptcha}>
        <Item
          className={styles.captchaInput}
          style={{
            marginBottom: 0,
          }}
        >
          <Input
            innerBefore={
              <img className={styles.imgIcon} src={codeIcon} alt="" />
            }
            name="mobileCode"
            size="large"
            {...init('mobileCode', {
              rules: [{ required: true, message: '验证码必填' }],
            })}
            placeholder="请输入验证码"
          />
        </Item>
        <Button
          type="secondary"
          size="large"
          disabled={!!countdown}
          loading={sending}
          onClick={sendPhoneCaptcha}
        >
          {sendBtnText}
        </Button>
      </div>
      <div className={styles.validateWrapper}>
        <Item>
          <Input
            size="large"
            innerBefore={
              <img className={styles.imgIcon} src={codeIcon} alt="" />
            }
            name="code"
            {...init('code', {
              rules: [{ required: true, message: '验证码不能为空' }],
            })}
            placeholder="请输入验证码"
          />
        </Item>
        <img
          src={imageUrl}
          onClick={getImageCaptcha}
          className={styles.codeImg}
          alt=""
        />
      </div>
    </>
  )
  return (
    <div className={styles.LoginBlock}>
      <div className={styles.innerBlock}>
        <p className={styles.desc}>
          <span className={styles.active}>融合通供应链科技平台运营后台</span>
        </p>
        <Form size="large" onSubmit={handleSubmit} field={field}>
          {accountForm}
          <Item
            style={{
              marginTop: 30,
            }}
          >
            <Form.Submit
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading}
              className={styles.submitBtn}
              validate
            >
              登录
            </Form.Submit>
          </Item>
        </Form>

        <p className={styles.pTips}>
          <span>忘记密码？请联系管理员</span>
        </p>
      </div>
    </div>
  )
}

const mapStateToProps = ({ login }) => ({ ...login })

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { loginAsync, getDictForList, queryAllRole, queryUserRole, logoutAsync },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(LoginBlock)

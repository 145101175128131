/**
 * @desc: 路由模块导出
 * @return Array
 */

let routerArr = [];
const routerMoudles = require.context('./modules', true, /\.js$/);

routerMoudles.keys().forEach((moudle) => {
  routerArr = routerArr.concat(routerMoudles(moudle).default);
});
export default routerArr;

import React, { useState } from 'react';
import { history } from 'ice';
import { Shell, ConfigProvider, Message } from '@alifd/next';
import PageNav from './components/PageNav';
import HeaderAvatar from './components/HeaderAvatar';
import Logo from './components/Logo';
// import Footer from './components/Footer';
import InviteCode from './components/InviteCode'

(function () {
  const throttle = function (type, name, obj = window) {
    let running = false;

    const func = () => {
      if (running) {
        return;
      }

      running = true;
      requestAnimationFrame(() => {
        obj.dispatchEvent(new CustomEvent(name));
        running = false;
      });
    };

    obj.addEventListener(type, func);
  };

  if (typeof window !== 'undefined') {
    throttle('resize', 'optimizedResize');
  }
})();

export default function BasicLayout({ children }) {
  const token = localStorage.getItem('sd-token');
  if (!token) {
    Message.warning('请先登录！', 2000);
    localStorage.clear();
    history.push('/user/login');
  }

  const getDevice = (width) => {
    const isPhone = typeof navigator !== 'undefined' && navigator && navigator.userAgent.match(/phone/gi);

    if (width < 680 || isPhone) {
      return 'phone';
    } else if (width < 1280 && width > 680) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  };

  const [device, setDevice] = useState(getDevice(NaN));

  if (typeof window !== 'undefined') {
    window.addEventListener('optimizedResize', (e) => {
      const deviceWidth = (e && e.target && e.target.innerWidth) || NaN;
      setDevice(getDevice(deviceWidth));
    });
  }

  return (
    <ConfigProvider device={device}>
      <Shell
        style={{
          height: '100vh',
        }}
        type="brand"
        fixedHeader={false}
      >
        <Shell.Branding>
          <Logo text="融合通供应链科技平台运营后台" />
        </Shell.Branding>
        <Shell.Action>
          <HeaderAvatar />
        </Shell.Action>
        <Shell.Navigation style={{ overflowX: 'hidden' }}>
          <PageNav />
        </Shell.Navigation>
        <Shell.Content>
          <div
            style={{
              height: '100%',
              overflow: 'hidden',
            }}
          >
            {children}
          </div>
          <InviteCode />
        </Shell.Content>
        {/* <Shell.Footer>
          <Footer />
        </Shell.Footer> */}
      </Shell>
    </ConfigProvider>
  );
}

import { lazy } from 'ice'

export default [
  {
    /* 平台总业绩 */
    path: '/reportManage/platform-achievement',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "report" */ '@/pages/ReportManage/PlatformAchievement'))
  },
  {
    /* 资金方统计 */
    path: '/reportManage/capital-statistic',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "report" */ '@/pages/ReportManage/CapitalStatistic'))
  },
]

import { lazy } from 'ice'

export default [
  {
    /* 银票询价 */
    path: '/orderManage/bankEnquiry',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "order" */ '@/pages/OrderManage/BankEnquiry'))
  },
  {
    /* 银票贴现 */
    path: '/orderManage/bankDiscount',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "order" */ '@/pages/OrderManage/BankDiscount'))
  },
  {
    /* 银票贴现-详情 */
    path: '/orderManage/bankDiscount-detail',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "order" */ '@/pages/OrderManage/BankDiscount/Detail'))
  },
  {
    /* 商票订单 */
    path: '/orderManage/business-order',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "order" */ '@/pages/OrderManage/BusinessOrder'))
  },
  {
    /* 商票订单-订单详情 */
    path: '/order/order-detail',
    name: 'BusinessOrderDetail',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "order" */ '@/pages/OrderManage/BusinessOrder/Detail'))
  },
  {
    /* 商票订单-贸易订单 */
    path: '/orderManage/trade-order',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "order" */ '@/pages/OrderManage/TradeOrder/index'))
  },
  // {
  //   /* 商票直融-普洛斯订单详情 */
  //   path: '/order/puluosi-order-detail',
  //   name: 'puluosiTicketOrderDetail',
  //   component: lazy(() => import(/* webpackChunkName: "order" */ '@/pages/OrderManage/Detail/Puluosi'))
  // },
  // {
  //   /* 商票直融-临商订单详情 */
  //   path: '/order/linshang-order-detail',
  //   name: 'linshangTicketOrderDetail',
  //   component: lazy(() => import(/* webpackChunkName: "order" */ '@/pages/OrderManage/Detail/Linshang'))
  // },
  // {
  //   /* 商票直融-京东订单详情 */
  //   path: '/order/jingdong-order-detail',
  //   name: 'jingdongTicketOrderDetail',
  //   component: lazy(() => import(/* webpackChunkName: "order" */ '@/pages/OrderManage/Detail/Jingdong'))
  // },
  // {
  //   /* 商票直融-深度保理订单详情 */
  //   path: '/order/shendubaoli-order-detail',
  //   name: 'shendubaoliTicketOrderDetail',
  //   component: lazy(() => import(/* webpackChunkName: "order" */ '@/pages/OrderManage/Detail/Shendubaoli'))
  // },
  // {
  //   /* 商票直融-腾讯云订单详情 */
  //   path: '/order/tengxunyun-order-detail',
  //   name: 'tengxunyunTicketOrderDetail',
  //   component: lazy(() => import(/* webpackChunkName: "order" */ '@/pages/OrderManage/Detail/Tengxunyun'))
  // },
]

import { request } from 'ice'

// 获取base64格式文件
export const queryFileBase64 = (data) => request({
  url: '/basics/file/queryFileByteCode',
  method: 'post',
  data: data || {}
})

// 机构查询
export const queryOrgByOrgIds = (data) => request({
  url: '/sysOrg/queryByOrgIds',
  method: 'post',
  data: data || {}
})


import { combineReducers } from 'redux';
import login from './login';
import user from './systemManage/user'
import capital from './capital'

export default combineReducers({
  login,
  user,
  capital
})

import { lazy } from 'ice'

/* 资方管理 */
export default [
  {
    /* 商票资方列表 */
    path: '/capitalManage/business-capital',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "capital" */ '@/pages/CapitalManage/BusinessCapital'))
  },
  {
    /* 银票资方列表 */
    path: '/capitalManage/bank-capital',
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "capital" */ '@/pages/CapitalManage/BankCapital'))
  },
]

import React, { Component } from 'react';
import { APP_MODE } from 'ice';
import { Dialog, Message } from '@alifd/next';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setInviteCode } from '@/models/systemManage/user'
import ClipboardJS from 'clipboard'
import config from '@/config'
import styles from './index.module.scss';

class InviteCode extends Component {
  componentDidMount() {
    this.codeCopy()
    this.linkCopy()
  }

  onClose = () => {
    this.props.setInviteCode(false)
  }

  copy = (copyDOM) => {
    copyDOM.on('success', () => {
      Message.success('复制成功！')
      copyDOM.destroy();
    });

    copyDOM.on('error', () => {
      Message.warning('复制失败，请手动复制！')
    });
  }

  codeCopy = () => {
    let clipboard = new ClipboardJS('#invitationCode')
    this.copy(clipboard)
  }

  linkCopy = () => {
    let clipboard = new ClipboardJS('#link')
    this.copy(clipboard)
  }

  getLink = () => {
    let temp = config[APP_MODE].inviteLink
    // if (APP_MODE === 'dev1') {
    //   temp = config.dev1.inviteLink || ''
    // } else if (APP_MODE === 'test1') {
    //   temp = config.test1.inviteLink || ''
    // } else if (APP_MODE === 'prod') {
    //   temp = config.prod.inviteLink || ''
    // }
    console.log(temp, 'temp')
    return temp
  }

  render() {
    const { inviteCodeIsVisible, userInfo } = this.props
    return (
      <Dialog
        title="邀请信息"
        visible={inviteCodeIsVisible}
        onClose={this.onClose}
        footer={false}
        style={{ width: '380px' }}
      >
        <div className={styles.title}>邀请码</div>
        <div className={styles.invite_code}>
          <div className={styles.content}>{userInfo.invitationCode}</div>
          <span id="invitationCode" className={styles.option} data-clipboard-text={userInfo.invitationCode} onClick={this.codeCopy}>点击复制</span>
        </div>
        <div className={styles.title} style={{ marginTop: '15px' }}>邀请链接</div>
        <div className={styles.invite_code}>
          <div className={styles.content}>{`${this.getLink()}/user/register?invitationCode=${userInfo.invitationCode}`}</div>
          <span
            id="link"
            className={styles.option}
            data-clipboard-text={`${this.getLink()}/user/register?invitationCode=${userInfo.invitationCode}`}
            onClick={this.linkCopy}
          >
            点击复制
          </span>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ user, login }) => ({ ...user, userInfo: login.userInfo })
const mapDispatchToProps = dispatch => bindActionCreators({ setInviteCode }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteCode)

import { Message } from '@alifd/next';
import { SUCCESS_CODE } from '@/utils/config';
import {
  queryUserForPage,
  updateUserInfo,
  resetPwd,
  deleteOneUser,
  getRoleInfo,
  getUserRole,
  getSysGroupData,
  changeRole,
  getUserGroupList,
  changeUserGroup,
  disable,
  enable,
  getUserDetail,
  getUserGroups
} from '@/services/systemManage/user';
import { queryOrgByOrgIds } from '@/services/common';

export const SET_USERLIST = 'user/SET_USERLIST';
export const SET_ORGINFO = 'user/SET_ORGINFO';
export const SET_USERINFO = 'user/SET_USERINFO';
export const SET_ALLROLE = 'user/SET_ALLROLE';
export const SET_USERROLE = 'user/SET_USERROLE';
export const SET_SYSGROUP = 'user/SET_SYSGROUP';
export const SET_USERGROUPLIST = 'user/SET_USERGROUPLIST';
export const SET_USERORGID = 'user/SET_USERORGID';
export const SET_USERDETAIL = 'user/SET_USERDETAIL';
export const SET_USERROLELIST = 'user/SET_USERROLELIST';
export const SET_ROLEINFO = 'user/SET_ROLEINFO';
export const SET_INVITEVISIBLE = 'user/SET_INVITEVISIBLE'

const initialState = {
  userInfoList: {},
  sysOrgList: {},
  userDetail: {},
  roleList: [],
  responseValues: [],
  userRoleList: [],
  sysGroupList: [],
  userGroupList: [],
  inviteCodeIsVisible: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USERLIST:
      return {
        ...state,
        userInfoList: action.userInfoList
      };
    case SET_ORGINFO:
      return {
        ...state,
        sysOrgList: action.sysOrgList
      };
    case SET_USERINFO:
      return {
        ...state,
        userDetail: action.userDetail
      };
    case SET_ALLROLE:
      return {
        ...state,
        roleList: action.roleList
      };
    case SET_USERROLE:
      return {
        ...state,
        userRoleList: action.userRoleList
      };
    case SET_SYSGROUP:
      return {
        ...state,
        sysGroupList: action.sysGroupList
      };
    case SET_USERGROUPLIST:
      return {
        ...state,
        userGroupList: action.userGroupList
      };
    case SET_USERORGID:
      return {
        ...state,
        userGroups: action.userGroups
      };
    case SET_USERDETAIL:
      return {
        ...state,
        userDetail: action.userDetail
      };
    case SET_USERROLELIST:
      return {
        ...state,
        userRoleList: action.userRoleList
      };
    case SET_ROLEINFO:
      return {
        ...state,
        roleInfo: action.roleInfo
      };
    case SET_INVITEVISIBLE:
      return {
        ...state,
        inviteCodeIsVisible: action.inviteCodeIsVisible
      };
    default:
      return state
  }
}

// 查询列表页数据
export const queryUserList = (params) => {
  return async (dispatch) => {
    const result = await queryUserForPage(params);
    const { data, count } = result
    dispatch({
      type: SET_USERLIST,
      userInfoList: { data, count }
    })
    return result
  }
}

// 查询机构数据
export const getorgInfo = (params) => {
  return async (dispatch) => {
    const { code, data, message } = await queryOrgByOrgIds(params);
    if (code === SUCCESS_CODE) {
      return dispatch({
        type: SET_ORGINFO,
        sysOrgList: data
      })
    } else {
      Message.warning(message)
    }
  }
}

// 修改用户信息
export const updateUser = (params) => {
  return async () => await updateUserInfo(params)
}

// 重置密码
export const resetPassword = (params) => {
  return async () => await resetPwd(params)
}

// 删除
export const deleteUser = (params) => {
  return async () => await deleteOneUser(params)
}

// 获取分配角色中所有角色
export const queryAllRole = (params) => {
  return async (dispatch) => {
    const result = await getRoleInfo(params);
    const { code, data } = result
    const roleList = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        roleList.push({ value: item.sysRoleId, label: item.name });
      });
    }
    dispatch({
      type: SET_ALLROLE,
      roleList: code === SUCCESS_CODE ? roleList : []
    })
    return result
  }
}

// 分配角色中此用户拥有的角色
export const queryUserRole = (params) => {
  return async (dispatch) => {
    const result = await getUserRole(params);
    const { code, data } = result
    const sysRoleIds = [];
    if (data && data.length) {
      data.forEach((item) => {
        sysRoleIds.push(item.sysRoleId);
      });
    }
    dispatch({
      type: SET_USERROLE,
      userRoleList: code === SUCCESS_CODE ? sysRoleIds : []
    })
    return result
  }
}

// 分配角色中此用户拥有的角色
export const changeUserRole = (params) => {
  return async (dispatch) => {
    dispatch({
      type: SET_USERROLE,
      ...params
    })
  }
}

// 分配角色中此用户拥有的角色
export const querySysGroupData = (params) => {
  return async (dispatch) => {
    const { code, data } = await getSysGroupData(params);
    if (code === SUCCESS_CODE) {
      const newData = []
      data.forEach((item) => {
        newData.push({ label: item.groupName, value: item.sysGroupId })
      })
      return dispatch({
        type: SET_SYSGROUP,
        sysGroupList: newData
      })
    }
  }
}

// 用户角色分配
export const updateRole = (params) => {
  return async () => await changeRole(params)
}

// 查询机构可关联的组列表
export const queryUserGroupList = (params) => {
  return async (dispatch) => {
    const { code, data } = await getUserGroupList(params);
    const sysGroupList = [];
    data && data.length > 0 && data.forEach((item) => {
      sysGroupList.push(item.sysGroupId);
    });
    return dispatch({
      type: SET_USERGROUPLIST,
      userGroupList: code === SUCCESS_CODE ? sysGroupList : []
    })
  }
}

// 获取机构关联的组
export const queryUserGroups = (params) => {
  return async (dispatch) => {
    const { code, data } = await getUserGroups(params);
    const groupList = [];
    data && data.length > 0 && data.forEach((item) => {
      groupList.push({ value: item.sysGroupId, label: item.groupName });
    });
    return dispatch({
      type: SET_USERORGID,
      userGroups: code === SUCCESS_CODE ? groupList : []
    })
  }
}

// 用户分配组
export const updateUserGroup = (params) => {
  return async () => await changeUserGroup(params)
}

// 列表禁用
export const userDisable = (params) => {
  return async () => await disable(params)
}

// 列表禁用
export const userEnable = (params) => {
  return async () => await enable(params)
}

// 用户详情页面
export const queryUserDetail = (params) => {
  return async (dispatch) => {
    const result = await getUserDetail(params);
    const { data } = result
    dispatch({
      type: SET_USERDETAIL,
      userDetail: data
    })
    return result
  }
}

// 设置邀请码
export const setInviteCode = (params) => {
  return (dispatch) => {
    dispatch({
      type: SET_INVITEVISIBLE,
      inviteCodeIsVisible: params
    })
  }
}

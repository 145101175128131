import { request } from 'ice';

/**
 * 登录
 * @param {*} data object
 */
export const userLogin = (data = {}) => request({
  url: '/basics/sysUser/login',
  method: 'post',
  data
});

/**
 * 验证码登录
 * @param {*} data object
 */
 export const captchaLogin = (data = {}) => request({
  url: '/basics/sysUser/codeLogin',
  method: 'post',
  data
});

/**
 * 获取登录验证码
 * @param {*} data object
 */
 export const getLoginCaptcha = (data = {}) => request({
  url: '/basics/customer/getLoginCaptcha',
  method: 'post',
  data: {
    loginType: 'ADMIN',
    ...data
  }
});

/**
 * 退出
 * @param {*} data object
 */
export const userLogout = (data = {}) => request({
  url: '/basics/sysUser/logout',
  method: 'post',
  data
});

/**
 * 查询数据字典
 * @param {*} data object
 */
export const queryDictForList = (data = {}) => request({
  url: '/basics/confDict/queryForPage',
  method: 'post',
  data
});

/**
 * 图形验证码
*/
export const queryImageCaptcha = (data) => request({
  url: '/basics/sysUser/getLoginImageCaptcha',
  method: 'post',
  data
});


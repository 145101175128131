import { request } from 'ice'
/**
 *
 * 商票资方管理模块
 */

// 商票资方分页查询
export const queryBusinessCapital = (data) => request({
  url: '/spb/partner/queryForPage',
  method: 'post',
  data: data || {}
})

// 商票资方查询
export const queryBusinessCapitalList = (data) => request({
  url: '/spb/partner/queryForList',
  method: 'post',
  data: data || {}
})

// 商票产品列表查询
export const queryBusinessProductList = data => request({
  url: '/spb/product/queryForPage',
  method: 'post',
  data: data || {}
})

// 资方服务开启
export const EnableBusinessCapitalService = data => request({
  url: '/spb/partner/enable',
  method: 'post',
  data: data || {}
})

// 资方排序
export const sortBusinessCapital = data => request({
  url: '/spb/partner/sort',
  method: 'post',
  data: data || {}
})


/** 银票资方 ** */

// 银票资方查询
export const queryBankCapitalList = (data) => request({
  url: '/yinpiao/partner/queryForList',
  method: 'post',
  data: data || {}
})

// 银票资方分页查询
export const queryBankCapital = (data) => request({
  url: '/yinpiao/partner/queryForPage',
  method: 'post',
  data: data || {}
})

// 银票资方服务开启
export const EnableBankCapitalService = data => request({
  url: '/yinpiao/partner/enable',
  method: 'post',
  data: data || {}
})

// 银票资方排序
export const sortBankCapital = data => request({
  url: '/yinpiao/partner/sort',
  method: 'post',
  data: data || {}
})
